import Carousel from 'react-slick';
import styled from 'styled-components';
import { GrNext, GrPrevious } from 'react-icons/gr';

const Carousels = ({
  children,
  dots = true,
  infinite = true,
  slideShow = 1,
  mobileSlideShow = 1,
  autoplay = true,
  pauseOnHover = true,
  autoplaySpeed = 3000,
  vertical = false
}) => {
  const settings = {
    dots: dots,
    speed: 1000,
    infinite: infinite,
    slidesToShow: slideShow || 3,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: autoplaySpeed,
    pauseOnHover: pauseOnHover,
    vertical: vertical,
    verticalSwiping: vertical,
    prevArrow: (
      <button className='slide-arrow prev-arrow' aria-label='prev-arrow'>
        <GrPrevious />
      </button>
    ),
    nextArrow: (
      <button className='slide-arrow next-arrow' aria-label='next-arrow'>
        <GrNext />
      </button>
    ),
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slideShow || 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: mobileSlideShow || slideShow || 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: mobileSlideShow || slideShow || 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: vertical ? mobileSlideShow || 3 : 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <CarouselWrapper className=' pb-30 custom-carousel'>
      <Carousel {...settings}>{children}</Carousel>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  .slick-dots {
    bottom: -30px;
    .slick-active {
      button:before {
        color: rgba(0, 0, 0, 1);
      }
    }
    button:before {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .slick-slider {
    .slide-arrow {
      display: none !important;
    }
  }

  @media (max-width: 690px) {
    margin-bottom: 0;
    .slick-slider {
      .slide-arrow,
      .slick-arrow {
        display: block !important;
        background-color: #000;
        border: none;
        padding: 14px;
      }
      .next-arrow,
      .slick-next,
      .prev-arrow,
      .slick-prev {
        position: absolute;
        top: 75%;
        transform: translate(0, -75%);

        width: unset;
        height: unset;
        &:before {
          content: unset;
        }
        svg {
          vertical-align: middle;
          font-size: 20px;
          color: #000;
          polyline {
            stroke: #ffe000;
          }
        }
      }
      .next-arrow,
      .slick-next {
        right: 8px;
      }
      .prev-arrow,
      .slick-prev {
        right: unset;
        left: 8px;
        z-index: 1;
      }
    }
  }
`;

export default Carousels;
